import './App.css';
import Router from './router/Router';

function App() {
  return (
    <Router></Router>
  );
}

export default App;
